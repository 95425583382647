import React, { useContext } from 'react';
import cx from 'classnames';
import { hotjar } from 'react-hotjar';
import { navigate } from '@reach/router';
import {
  ShoppingBagIcon,
  UsersIcon,
  GridSquare2X2Icon,
  SendIcon,
  SettingsIcon,
  UserIcon,
  CreditCardIcon,
  ArrowUpRightIcon,
  ChartColumnIcon,
  NotificationIcon,
  GiftIcon,
  HelpIcon,
  EyeIcon,
  StoreIcon,
} from '@zydalabs/zac-icons-react';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import { useReplaceParams, useMobile } from 'hooks/index';
import * as paths from 'paths.js';
import { ReactComponent as VerdIcon } from 'assets/verdIcon.svg';
import { ReactComponent as Arrow } from 'assets/Arrow_narrow.svg';
import { ReactComponent as Orderfast } from 'assets/orderfast-icon.svg';
import Item from './Item';
import REFER_PROGRAM_LINKS from '../constants';
import { CAMPAIGN_PERMISSIONS } from 'constants/users';
import Store from 'common/components/Store';
import UserMenu from '../UserMenu';
import { getStoreURl } from '../utils';
import { COUNTRY_CODES_ENUM } from 'utils/enums';

const Menu = () => {
  const { lang } = useContext(localeContext);
  const isMobile = useMobile();
  const replace = useReplaceParams();
  const {
    hasRole,
    user,
    subscriptionStatus,
    selectedStore,
    campaignsPermission,
    settings,
    isCustomerSupport,
  } = useContext(userContext);
  const isSupport = isCustomerSupport(user);

  const storeUrl = getStoreURl(selectedStore);
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION);
  const { id, published, __typename, countryCode, deploymentLocation } = selectedStore || {};

  if (user?.id)
    window.hj('identify', user.id, {
      user_role: user.roles[0].role,
      user_email: user.email,
      store_id: id,
      store_status: published ? 'published' : 'unpublished',
      store_type: __typename,
      subscription_status: subscriptionStatus,
    });

  const isManagerOrHigher = hasRole('owner') || hasRole('ops_manager');

  const nestedMenu = [
    {
      title: <Text value={translations.BUSINESS_INFORMATION} />,
      to: paths.businessInformation,
      testId: 'businessInformation',
    },
    {
      title: <Text value={translations.BRANDING} />,
      to: paths.branding,
      testId: 'branding',
    },
    {
      title: <Text value={translations.STORE_LAYOUT} />,
      to: paths.storeLayout,
      testId: 'storeLayout',
    },
    {
      title: <Text value={translations.BUSINESS_LOCATIONS} />,
      to: paths.branchList,
      testId: 'businessLocation',
    },
    {
      title: <Text value={translations.DELIVERY} />,
      to: settings?.useDeliveryzonesMs ? paths.zonesTableList : paths.deliveryList,
      testId: 'delivery',
    },
    {
      title: <Text value={translations.ORDERS_MANAGEMENT} />,
      to: paths.ordersManagement,
      testId: 'orderManagement',
    },
    {
      title: <Text value={translations.PAYMENT_GATEWAYS} />,
      to: paths.paymentGateways,
      testId: 'paymentMethods',
    },
    {
      title: <Text value={translations.INTEGRATIONS} />,
      to: paths.integrations,
      testId: 'integrations',
    },
    {
      title: <Text value={translations.WEB_LINKS} />,
      to: paths.webLinks,
    },
  ];

  return (
    <>
      <div className="flex flex-col relative layout-side-bar">
        {!isMobile && (
          <div className="pb-4">
            <Store onClick={() => navigate(replace(paths.switchStore, selectedStore.id))} isMain {...selectedStore} />
          </div>
        )}
        <div className="flex flex-col w-full">
          <div>
            {(hasRole('owner') || hasRole('ops_manager')) && isSupport && (
              <Item firstLink={paths.overview} testId="overview" to={paths.overview} icon={StoreIcon}>
                <Text value={translations.OVERVIEW} />
              </Item>
            )}

            <Item firstLink={paths.currentOrders} testId="orders" to={paths.orders} icon={ShoppingBagIcon}>
              <Text value={translations.ORDERS} />
            </Item>

            {hasRole('operator') && (
              <>
                <Item firstLink={paths.itemTracking} testId="itemTracking" to={paths.itemTracking} icon={UserIcon}>
                  <Text value={translations.ITEM_TRACKING} />
                </Item>

                <Item
                  firstLink={paths.operatorOptionGroups}
                  testId="menuOptionGroups"
                  to={paths.menuOptionGroups}
                  icon={UserIcon}
                >
                  <Text value={translations.PRODUCTS_OPTION_GROUPS} />
                </Item>
              </>
            )}

            {(hasRole('owner') || hasRole('ops_manager')) && (
              <>
                <Item firstLink={paths.customers} testId="customers" to={paths.customers} icon={UsersIcon}>
                  <Text value={translations.CUSTOMERS} />
                </Item>
                <Item
                  firstLink={paths.salesAnalytics}
                  testId="analytics"
                  to={paths.analytics}
                  icon={ChartColumnIcon}
                  nested={[
                    {
                      title: <Text value={translations.SALES_ANALYTICS} />,
                      to: paths.salesAnalytics,
                      testId: 'salesAnalytics',
                    },
                    {
                      title: <Text value={translations.BEHAVIOR_BREAKDOWN} />,
                      to: paths.behaviorBreakdown,
                      testId: 'behaviourBreakdown',
                    },
                    {
                      title: <Text value={translations.BEST_SELLING} />,
                      to: paths.bestSelling,
                      testId: 'bestSelling',
                    },
                    {
                      title: (
                        <Text
                          value={
                            settings.useDeliveryzonesMs ? translations.GEO_ANALYTICS_ZONES : translations.GEO_ANALYTICS
                          }
                        />
                      ),
                      to: paths.geoAnalytics,
                      testId: 'geoAnalytics',
                    },
                  ]}
                >
                  <Text value={translations.ANALYTICS} />
                </Item>
                <Item
                  firstLink={paths.menuOverview}
                  testId="orders"
                  to={paths.menu}
                  icon={GridSquare2X2Icon}
                  nested={[
                    {
                      title: <Text value={translations.PRODUCTS} />,
                      to: paths.menuOverview,
                      testId: 'menuItems',
                    },
                    {
                      title: <Text value={translations.PRODUCTS_OPTION_GROUPS} />,
                      to: paths.menuOptionGroups,
                      testId: 'menuOptionGroups',
                    },
                    {
                      beta: true,
                      title: <Text value={translations.ITEM_TRACKING} />,
                      firstLink: paths.itemTracking,
                      to: paths.variantItemTracking,
                      testId: 'menuItemTracking',
                    },
                  ]}
                >
                  <Text value={translations.MENU} />
                </Item>
                <Item
                  firstLink={paths.allVouchers}
                  testId="marketing"
                  to={paths.marketing}
                  icon={SendIcon}
                  isNew
                  nested={[
                    {
                      title: <Text value={translations.VOUCHERS} />,
                      to: paths.allVouchers,
                      testId: 'vouchers',
                    },
                    ...(![CAMPAIGN_PERMISSIONS.NOT_SUPPORTED_PLAN, CAMPAIGN_PERMISSIONS.FALSE].includes(
                      campaignsPermission,
                    )
                      ? [
                          {
                            title: <Text value={translations.CAMPAIGNS} />,
                            to: paths.campaigns,
                            testId: 'campaigns',
                          },
                        ]
                      : []),
                    {
                      title: <Text value={translations.SOCIAL_MEDIA_TRACKING} />,
                      to: paths.socialmediatracking,
                      testId: 'socialmediatracking',
                    },
                    {
                      title: <Text value={translations.QR_CODE} />,
                      to: paths.qrCodePage,
                      testId: 'qr-code-page',
                    },
                    ...((countryCode === COUNTRY_CODES_ENUM.KUWAIT) ? [
                      {
                        title: <Text value={translations.ENGAGE_AUTO_PILOT} />,
                        to: paths.engageautopilot,
                        testId: 'engageautopilot',
                        new: true,
                      }
                    ] : []).filter(Boolean),
                  ]}
                >
                  <Text value={translations.MARKETING} />
                </Item>
                <Item
                  firstLink={paths.businessInformation}
                  testId="setup"
                  to={paths.setup}
                  icon={SettingsIcon}
                  nested={nestedMenu}
                >
                  <Text value={translations.SETUP} />
                </Item>
              </>
            )}
            {isManagerOrHigher && (
              <Item
                firstLink={isManagerOrHigher ? paths.loyalty : paths.links}
                testId="orderfast"
                to={paths.orderfast}
                icon={Orderfast}
                nested={[
                  {
                    title: <Text value={translations.LOYALTY} />,
                    to: paths.loyalty,
                    testId: 'orderfastLoyalty',
                  },
                  {
                    title: <Text value={translations.WALLET} />,
                    to: paths.wallet,
                    testId: 'orderfastWallet',
                  },
                  {
                    title: <Text value={translations.LINKS} />,
                    to: paths.links,
                    testId: 'orderfastLinks',
                  },
                ]}
              >
                <Text value={translations.ORDERFAST} />
              </Item>
            )}

            <>
              {hasRole('owner') && (
                <Item firstLink={paths.team} testId="team" to={paths.team} icon={UserIcon}>
                  <Text value={translations.TEAM} />
                </Item>
              )}
            </>
            <Item firstLink={paths.verd} testId="verd" to={paths.verd} icon={VerdIcon} isNew>
              <Text value={translations.VERD} />
            </Item>
            {(hasRole('owner') || hasRole('ops_manager')) && (
              <>
                <Item firstLink={paths.billing} testId="billing" to={paths.billing} icon={CreditCardIcon}>
                  <Text value={translations.BILLING_AND_PLANS} />
                </Item>
                <Item
                  firstLink={paths.salesReports}
                  testId="salesReports"
                  to={paths.salesReports}
                  icon={ArrowUpRightIcon}
                >
                  <Text value={translations.SALES_REPORT} />
                </Item>
                <div>&nbsp; </div>
              </>
            )}
          </div>
          <div>
            <div id="beamerUpdatesButton" className={cx(lang === 'en' && 'leftIcon')}>
              <Item firstLink="" testId="news" icon={NotificationIcon}>
                <Text value={translations.UPDATES} />
              </Item>
            </div>
            {/* <Item firstLink="" testId="help" link={`https://help.zyda.com/${lang}`} icon={HelpIcon}>
              <Text value={translations.HELP_CENTER} />
            </Item> */}
            <Item
              firstLink=""
              testId="help"
              link={REFER_PROGRAM_LINKS[countryCode] || REFER_PROGRAM_LINKS['KW']}
              icon={GiftIcon}
            >
              <div className="flex flex-col">
                <Text value={translations.REFER_PROGRAM.TITLE} />
                <div className={cx('flex', lang === 'en' ? 'flex-row' : 'flex-row-reverse')}>
                  <Text
                    full={false}
                    className="text-xs text-primary-base font-medium"
                    value={translations.REFER_PROGRAM.ACTION}
                  />
                  <Arrow className={cx('mx-2', lang === 'ar' && 'transform rotate-180')} />
                </div>
              </div>
            </Item>
            {storeUrl && (
              <a
                target="_blank"
                href={storeUrl}
                className={cx(
                  'flex flex-row rounded hover:bg-gray-200 items-center p-2 my-1 cursor-pointer',
                  lang === 'ar' && 'flex-row-reverse',
                  isMobile && 'pb-6',
                )}
              >
                <div className={cx('text-sm material-icons', lang === 'ar' ? ' ml-2 ' : ' mr-2')}>
                  <EyeIcon width="20" color="#373E5A" />
                </div>
                <Text value={translations.VISIT_STORE} />
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="pt-4 pb-2 flex flex-col border-gray-300 border-t">
        <UserMenu />
      </div>
    </>
  );
};

export default Menu;
